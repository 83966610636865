import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter as Router } from 'connected-react-router'
import store, { history } from '~/store'
import { FeaturesWrapper } from '@bufferapp/features'
import BugsnagErrorBoundary from './BugsnagErrorBoundary'
import { HttpLink } from 'apollo-link-http'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import App from './components/App'
import ProfileLoader from '~/profile-loader'
import { env } from '~/env'

store.dispatch({
  type: 'APP_INIT',
})

const client = new ApolloClient({
  // @ts-expect-error TS(2739) FIXME: Type 'HttpLink' is missing the following propertie... Remove this comment to see the full error message
  link: new HttpLink({
    uri: env.VITE_GRAPHQL_API,
    credentials: 'include',
    headers: {
      'x-buffer-client-id': 'webapp-analytics',
    },
  }),
  cache: new InMemoryCache(),
})

// @ts-expect-error TS(7006) FIXME: Parameter 'AppComponent' implicitly has an 'any' t... Remove this comment to see the full error message
const renderApp = (AppComponent) => {
  render(
    <ApolloProvider client={client}>
      <FeaturesWrapper>
        <Provider store={store}>
          <Router history={history}>
            <BugsnagErrorBoundary>
              <ProfileLoader>
                <AppComponent />
              </ProfileLoader>
            </BugsnagErrorBoundary>
          </Router>
        </Provider>
      </FeaturesWrapper>
    </ApolloProvider>,
    document.getElementById('root'),
  )
}

renderApp(App)
